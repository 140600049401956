<template>
  <q-layout view="hHh lpR fFf" class="app_bg canna_pd_Page">

    <q-header class="canna_header_bg noselect">
      <q-toolbar>

        <template v-if="(pageSize === 'xs' || pageSize === 'sm') ? true : false">
          <q-btn dense flat round icon="menu" class="canna_header lt-md" @click="drawerLeft = !drawerLeft" />
        </template>

        <q-toolbar-title class="header_title" style="display: flex;justify-content: flex-start;">
          <div class="action_item_generic" @click.stop="goToMainPage">Canna</div>
        </q-toolbar-title>

        <template v-if="profile_foto!==null">
          <q-avatar class="app_avatar_generic app_avatar_generic_24" style="margin-right: 6px;">
            <img :src="'/personas/persona_2.jpg'">
          </q-avatar>
        </template>
        <template v-else>
          <q-avatar class="app_avatar_generic app_avatar_generic_24 headerAvatar_text" style="margin-right: 6px;">
            {{profile_abrev}}
          </q-avatar>
        </template>

        <q-icon name="expand_more" class="actionIcon canna_header_icon" style="margin-right: 12px;">
          <q-menu anchor="bottom middle" self="top end">
            <q-item clickable v-close-popup @click.stop="goToDashboard">
              <q-item-section>Atras</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click.stop="goToCloseSession">
              <q-item-section>Cerrar sesión</q-item-section>
            </q-item>
          </q-menu>
        </q-icon>

        <whatsapp-button size="30px" />
        <!-- 
        <cxIcon 
          iconType="bell"
          size="24px"
          class="xxx-demo zzz-prueba"
          :cls="'icon_svg_off'"
          :overCls="'icon_svg_offB'"
          :state="0"
          :hasMouseOver="true"
          :hasAction="true"
          :allowDisabled="true"
          :allowClick="true"
          @iconClick="onClickHeaderBell"
        />
        -->
      </q-toolbar>
    </q-header>

    <q-drawer v-model="drawerLeft" :width="192" show-if-above content-class="canna_drawer" v-if="useSideBarHnD">

      <q-scroll-area style="height:calc(100% - 56px);margin-top: 56px;"><!-- margin-top: 56px; -->

        <q-list>
          <q-item clickable v-ripple
            tag="a"
            class="planMenuItem"
            active-class="planMenu_active"
            :active="($route.name==='settings_main_account')"
            :to="'/settings'"
            :exact="true"
          >
            <div class="planmenu_pointer" v-if="($route.name==='settings_main_account')">&nbsp;</div>
            <q-item-section>Cuenta</q-item-section>
            <q-item-section avatar>
              <cxIcon 
                iconType="star"
                size="24px"
                class="xxx-demo zzz-prueba"
                :cls="'canna_menuIcon'"
                :state="($route.name==='settings_main_account') ? 1 : 0"
                :hasMouseOver="true"
                :hasAction="true"
                :allowDisabled="true"
                :allowClick="true"
                @iconClick="goPage('account')"
              />
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple
            tag="a"
            class="planMenuItem"
            active-class="planMenu_active"
            :active="($route.name==='settings_main_password')"
            :to="'/settings/password'"
            :exact="true"
          >
            <div class="planmenu_pointer" v-if="($route.name==='settings_main_password')">&nbsp;</div>
            <q-item-section>Contraseña</q-item-section>
            <q-item-section avatar>
              <cxIcon 
                iconType="cannav2_settings_password"
                size="24px"
                class="xxx-demo zzz-prueba"
                :cls="'canna_menuIcon'"
                :state="($route.name==='settings_main_password') ? 1 : 0"
                :hasMouseOver="true"
                :hasAction="true"
                :allowDisabled="true"
                :allowClick="true"
                @iconClick="goPage('password')"
              />
            </q-item-section>
          </q-item>

        </q-list>

      </q-scroll-area>

      <div class="absolute-top dashGoBackContainer" style="">
        <div class="dashGoBackZone noselect action_item" @click.stop="goToDashboard">
          <div>
            <cxIcon
              iconType="ui_goback"
              size="24px"
              class=""
              :cls="'icon_close_svg'"
              :state="0"
              :hasMouseOver="true"
              :hasAction="true"
              :allowDisabled="true"
              :allowClick="true"
              @iconClick="goToDashboard"
            />
          </div>
          <div class="title">Atras</div>
        </div>
      </div>

    </q-drawer>

    <q-page-container id="canna_pl_pageContainer" class="canna_pd_Page">
      <keep-alive>
        <router-view />
      </keep-alive>
    </q-page-container>

  </q-layout>
</template>
<script>
import { requestPack } from '../boot/axios' // APP code
import { canna_cookieMethods } from './../boot/__cannav2_app' // Cookies method 

import myplatform_Mixin from '../mixins/platformMix.js'
import logOut_Mixin from '../mixins/requestMix.js'
import helperBase_Mixin from '../mixins/helperBase.js'

export default {
  name: 'settings_main_layout',
  mixins: [
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin
  ],
  components: {
    cxIcon: require('components/appIcons.vue').default
  },
  data () {
    return {
      a_name: 'layout settings canna 201',
      version: '201',
      useDrawer: false,
      drawerLeft: false,
      info: {
        data: []
      },
      progressthick: 0.35,
      profileComplete: false,  // Temporales
      profileProgress: 15 // Temporales
    }
  },
  computed: {
    appLogin () {
      return this.$store.state.myapp.appHasLogin
    },
    useSideBarHnD () {
      var rutan = this.$route.name 
      var nouseHd = [ ]
      return (nouseHd.indexOf(rutan) >= 0) ? false : true
    },
    profile_foto () {
      if (this.$store.state.myapp.appUserProfile.hasOwnProperty('imagenFoto')){ 
        return this.$store.state.myapp.appUserProfile.imagenFoto
      } else { 
        return null
      }
    },
    profile_abrev () {
      return this.$store.state.myapp.appUserProfile.abreviatura || 'CM' 
    }
  },
  methods: { 
    ...requestPack,
    ...canna_cookieMethods, 
    onClickHeaderBell: function () { 
      this.$cannaDebug('-- PV2 -- Main Layout -- bell clicked')
    },
    goToMainPage () { 
      var newRuta = '/dashboard'
      setTimeout(() => {
        this.$router.push(newRuta)
      }, 150)
    },
    goPage (pagename) {
      this.$cannaDebug('-- PV2 -- plan Layout -- go To PAGE -- ' + pagename)
    },
    goToDashboard () { 
      this.$cannaDebug('-- PV2 -- Main Layout -- go To Dashboard')
      this.$router.push('/dashboard')
    },
    goToCloseSession (){
      this.$cannaDebug('-- PV2 -- Main Layout -- go To CloseSession')
      var test = this.cannack_destroyCookie( this.$q.cookies, 'CannaV2Public' )
      this.$cannaDebug('-- PV2 -- Main Layout -- goToCloseSession --- ' + test)
      window.location = '/'
    },
  }
}
</script>